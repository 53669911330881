<template>
  <div class="home-solution-jiazhi-box">
    <a-carousel autoplay>
      <div class="home-solution-jiazhi-list" v-for="item in val" :key="item.id">
        <div>
          <div>
            <p>{{item.name}}</p>
            <span>{{item.valueDiscription}}</span>
          </div>
          <span><v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/solution/cmrt.png')" /></span>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  components:{
    VImg
  },
  props:['val'],
};
</script>

<style lang="scss" scoped>
.home-solution-jiazhi-box {
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.14);
  .home-solution-jiazhi-list {
    > div {
      display: flex;
      > div {
        flex:1;
        padding: 50px 30px;
        > p {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
          color: #333;
        }
        > span {
          font-size: 16px;
          display: block;
          line-height: 24px;
          color: #666;
          text-align: justify;
        }
      }
      >span{
        width: 622px;
        >img{
            width: 100%;
            height: 100%;
        }
      }
    }
  }
}
</style>
<style>
.home-solution-jiazhi-box .ant-carousel .slick-dots li button {
  background: #ccc;
}
.home-solution-jiazhi-box .ant-carousel .slick-dots li.slick-active button {
  background: #EA0B06;
}
.home-solution-jiazhi-box .ant-carousel .slick-dots-bottom {
  bottom: -50px;
}
.home-solution-jiazhi-box .slick-track{
  height: 345px;
}
</style>
